import React from 'react'
import { Layout, Faq, Seo } from '../components'

export default function FAQ() {
  return (
    <Layout>
      <Seo title="Faq" />
      <Faq />
    </Layout>
  )
}
